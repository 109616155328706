import React from 'react'

function NabvBar2() {
    console.log('nav2')
    return (
        <div className="p-5 bg-black">
            <span className="font-bold text-white text-2xl cursor-pointer tracking-wider">BackMarket</span>    
        </div>
    )
}

export default NabvBar2
